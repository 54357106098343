import Link from "next/link";

const CopyRight = (props: any) => {
    return (
        <>
            <div className="md:col-span-12 col-span-12">
                <div className="block grid-cols-12 w-[100%] mb-4 text-left lg:text-left md:text-left">
                    <p className="text-[#FFFFFF] lg:text-[13px] md:text-[13px] text-[13px] mb-0">
                        {/* Copyright &copy; 2023 ExC Academy Private Limited. All rights reserved. */}
                        Copyright 2025 &copy; <Link href="/" target="_blank" className="hover:text-[#9014DC]" rel="noopener">ExC academy</Link>
                    </p>
                </div>
            </div>
        </>
    )
}
export default CopyRight;