"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from "next/image";
import { useEffect } from 'react';

const Advantages = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);
    return (
        <section className="bg-[#FAFAFA] xl:pt-14 lg:pt-14 md:pt-14 pt-7">
            <div className="container" data-aos="fade-up" data-aos-duration="1000">
                <div className="grid grid-cols-1">
                    <div>
                        <h2 className="text-center text-gray font-semibold text-[20px] lg:text-[40px] md:text-[30px] mb-5 leading-tight">Advantages at a Glance: ExC&apos;s Benefits</h2>
                        <p className="text-center text-gray font-normal pt-2 pb-5 text-[1rem] lg:text-xl md:text-base">Explore the array of features that make ExC academy the ultimate choice for <br className="hidden lg:block md:block" /> elevating and enriching your learning experience.</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 lg:gap-[50px] md:gap-[50px] gap-0 mt-7">
                    <div>
                        <div className="overflow-hidden rounded-tl-[30px] rounded-br-[45px] h-auto m-auto">
                            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/advantages-classroom-designed-for-professionals.webp"
                                className="md:h-[265px] object-cover lg:h-[initial] mx-auto" height={371} width={500} alt="Main img"
                                blurDataURL="URL"
                                placeholder="blur"
                                fetchPriority="high"
                                loading="eager"
                                rel="preload"
                            />
                        </div>
                    </div>
                    <div>
                        <div className="mt-[20px] lg:mt-0 md:mt-0">
                            <h3 className="text-gray text-[20px] lg:text-[32px] md:text-2xl font-semibold lg:mb-10 mb-3 leading-tight">A classroom designed for the professionals</h3>
                            <div className="flex items-center border border-[#C7C7C7] px-4 py-1.5">
                                <Image height={23} width={27} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/icon_1.webp"
                                    className="me-4 " alt="A Classromm"
                                    blurDataURL="URL"
                                    placeholder="blur"
                                    fetchPriority="high"
                                    loading="eager"
                                    rel="preload"
                                />
                                <p className="text-gray font-normal text-[1rem] lg:text-[17px] md:text-sm ">Experienced industry professionals as instructors.</p>
                            </div>
                            <div className="flex items-center border border-[#C7C7C7] px-4 py-1.5 lg:my-5 md:my-3 my-3">
                                <Image height={23} width={27} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/icon_2.webp"
                                    className="me-4 " alt="Teachers"
                                    blurDataURL="URL"
                                    placeholder="blur"
                                    fetchPriority="high"
                                    loading="eager"
                                    rel="preload"
                                />
                                <p className="text-gray font-normal text-[1rem] lg:text-[17px] md:text-sm">Industry-Relevant Curriculum – Supported by expert workshops.</p>
                            </div>
                            <div className="flex items-center border border-[#C7C7C7] px-4 py-1.5">
                                <Image height={23} width={27} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/icon_3.webp"
                                    className="me-4 w-[34px]" alt="Student"
                                    blurDataURL="URL"
                                    placeholder="blur"
                                    fetchPriority="high"
                                    loading="eager"
                                    rel="preload"
                                />
                                <p className="text-gray font-normal text-[1rem] lg:text-[17px] md:text-sm ">Course guided by Exalogic Consulting, a legacy of 15+ years in IT Consulting.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Advantages;