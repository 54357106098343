"use client"
import { useEffect } from 'react';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from 'next/image';
import { Button } from '../ui/button';
import Link from 'next/link';
import DownloadBrochurePopup from '@/components/course-details/download-brochure-popup'

const KeyDate = [
    {
        id: 0,
        title: 'Classroom Learning Program',
        content: "Step into the immersive world of learning with ExC Academy. Our dynamic and interactive classrooms foster an environment where knowledge meets innovation. From expert-led sessions to collaborative learning experiences, we empower students with cutting-edge resources and personalized guidance.",
        img: 'https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/key_img_1.webp',
    },
    {
        id: 1,
        title: 'Career Assistance',
        content: 'At ExC Academy, we stand by our commitment to your success. Our rigorous training and personalized career support, empower you to embark on your professional journey with confidence and assurance. Join us and step into a world of SAP opportunities!',
        img: 'https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/key_img_2.webp',
    }
]

const KeyOffering = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);
    return (
        <section className="key-offering py-7 lg:py-14 md:py-14 bg-[#F4F2F2]">
            <div className="container" data-aos="fade-up" data-aos-duration="1000">
                <div className="grid grid-cols-1">
                    <div>
                        <h2 className="text-center text-gray font-semibold lg:text-[36px] md:text-2xl text-[20px] leading-tight">Explore Our Features</h2>
                        <p className="text-center text-gray font-normal py-5 text-[16px] lg:text-[16px] md:text-[16px]">
                        Dive deep into EXC Academy's world of features, where innovation meets functionality. From cutting-edge technology to seamless user experiences, our meticulously crafted offerings redefine learning, ensuring you have every tool needed for success in your hands.
                        </p>
                    </div>
                    <div className='bg-white md:p-8 p-4 mt-4 shadow'>
                        <div className='grid grid-cols-12 gap-4'>
                            <div className='md:col-span-7 col-span-12 order-2 md:order-1'>
                                {/* <h3 className='lg:text-[28px] font-semibold md:text-xl text-[18px] leading-tight mb-4'>Build a Better Future with SAP Transformations</h3> */}
                                <h3 className='lg:text-[28px] font-semibold md:text-xl text-[18px] leading-tight mb-4'>Build a Better Future with AI Mastery</h3>
                                <p className='mb-4 md:text-[16px]'>At ExC Academy, our AI course is thoughtfully crafted to stay aligned with the latest advancements in artificial intelligence, keeping pace with the dynamic global business environment. This ensures that learners are equipped to drive innovation and improve outcomes on a global scale.</p>
                                <p className='mb-4 md:text-[16px]'>Our holistic training programs empower individuals with the expertise and practical skills required to thrive in complex AI-driven projects. We are dedicated to delivering high-quality education that helps our students build a successful career in the ever-evolving field of artificial intelligence.</p>
                                <h4 className='md:text-[18px] font-semibold mb-3 mt-8'>Our program emphasizes on:</h4>
                                <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                                    <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                                    <p className="lg:text-[16px] md:text-[14px] text-[14px] font-normal pt-[5px] ml-3">
                                    Online training with smaller batch size </p>
                                </div>
                                <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                                    <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                                    <p className="lg:text-[16px] md:text-[14px] text-[14px] font-normal pt-[5px] ml-3">
                                    Weekday and weekend course delivery </p>
                                </div>
                                <div className="flex xs:items-center sm:items-normal mb-4 last:mb-0 w-full items-center">
                                    <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/like.svg" alt="like" width='25' height='25' />
                                    <p className="lg:text-[16px] md:text-[14px] text-[14px] font-normal pt-[5px] ml-3">
                                    Capstone project expertise with real world examples </p>
                                </div>
                                <div className='flex flex-wrap items-center gap-3 mt-10'>
                                    <div>
                                    {/* <DownloadBrochurePopup courseid={"70a332ba-b3d4-46a0-a44a-d6f4e15556e4"} coursecid={"31bd881f-b2a2-4ea1-8503-c99c4e2dc9fe"} coursename={"SAP-ARIBA CERTIFICATION TRAINING"} /> */}
                                        {/* <Button variant='outline' className='uppercase hover:bg-[#121212] hover:text-[#FFFFFF] text-[#121212] font-semibold text-[0.9rem] lg:text-lg md:text-base px-3 sm:px-7 py-3 sm:py-3 border-solid border-2 border-[#121212]  rounded-none inline-block no-arrow h-[55px]'> Download Broucher</Button> */}
                                    </div>
                                    <div>
                                        <Link href="#inquiriesFeedBack" className='inline-block hover:bg-gray-800 bg-black text-white font-xl uppercase px-3 sm:px-7 py-3 sm:py-4 text-center font-semibold text-base lg:inline-block md:inline-block rounded-none leading-normal h-[55px]'>Enquire Now</Link>
                                        </div>
                                </div>
                            </div>
                            <div className='md:col-span-5 col-span-12 order-1 md:order-2'>
                                <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/girl-with-book.webp" alt="girl-with-book" width='441' height='578' className='max-w-full m-auto text-center'/>
                            </div>
                        </div>

                        {/* <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-2 gap-x-20 sm:gap-x-7 md:gap-x-5 mt-7 lg:gap-y-0 md:gap-y-7 gap-y-5">
                            {KeyDate.map((i) => (
                                <div key={i.id} className={`bg-[url('https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/key-bg.webp')] overflow-hidden rounded-[10px] shadow-md 
                                lg:h-[375px] md:h-[220px] sm:h-[670px] h-[670px]
                                relative
                                block
                                bg-cover
                                bg-no-repeat
                                bg-center
                                before:content-['']
                                before:absolute
                                before:inset-0
                                before:block
                                before:bg-gradient-to-l
                                before:from-transparent
                                before:to-[#302A33]
                                before:opacity-75
                                before:z-[-5]`}
                                >
                                    <div className="block lg:flex md:flex lg:h-auto md:h-auto h-auto">
                                        <div className="w-[100%] lg:w-[60%] md:w-[80%] py-7 px-5 sm:h-auto">
                                            <h3 className="text-[20px] lg:text-[22px] md:text-[22px] text-white font-semibold pb-5 leading-tight">{i.title}</h3>
                                            <p className="text-white font-normal lg:text-base md:text-sm text-[1rem]">
                                                {i.content}
                                            </p>
                                        </div>
                                        <div className='lg:w-[40%] md:w-[20%]'>
                                            <Image src={i.img} alt="key-offerings-img" width={235} height={375} className='w-full lg:h-[375px] md:h-[280px] sm:h-[480px] h-[480px] object-cover object-top' />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default KeyOffering;