"use client"

import {
    CourseCard,
    CourseCardContent,
    CourseCardDescription,
    CourseCardFooter,
    CourseCardHeader,
    CourseCardTitle,
  } from "@/components/ui/card"
  import moment from 'moment'
  import Image from "next/image"
  import ApplyNowPopupModule from "./apply-now-popup"
  import ReadMore from "./read-more-button"
  import StarRating from "../course-details/star-rating"
  import Link from "../ui/link"
  import { Button } from "../ui/button"
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
  import { MdKeyboardArrowLeft, MdKeyboardArrowRight  } from "react-icons/md";

  type CardProps = React.ComponentProps<typeof CourseCard>
  
  const CourseSlider =  ({data, session}:any) => {
    const PrevArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-prev">
                <MdKeyboardArrowLeft className="sm:text-[40px] text-[30px]" />
            </div>
        );
    };

    const NextArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-next">
                <MdKeyboardArrowRight className="sm:text-[40px] text-[30px]" />
            </div>
        );
    };

    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        lazyLoad: true,
        arrows: false,
        customNav: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };
    return (
      <section className="md:mt-8 mt-5">
        <div className="container"   >
            <Slider {...settings} className="banner-silder">
          {data?.map((courseItem: Course, index: Course) => (
            <CourseCard className="md:mb-8 mb-5" key={courseItem.course_id}>
              <div className="grid grid-cols-12 gap-0 w-[100%]">
                <div className="lg:col-span-8 md:col-span-8 col-span-12 md:order-1 order-2 relative">
                  <Image
                    height={10} width={2}
                    src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/our-courses/line.webp"
                    className="h-full w-[1px] md:block hidden absolute right-0 top-0 z-[2]"
                    alt="line img"
                    fetchPriority="high"
                    loading="eager"
                    rel="preload"
                  />
                  <CourseCardHeader>
                    <div className="flex flex-wrap items-center">
                      <div className="sm:mr-5 mr-4 mb-1 lg:text-start md:text-start text-center">
                        <CourseCardTitle>{courseItem.title}</CourseCardTitle>
                      </div>
                      {courseItem.upcoming === 'true' && <div className="rounded-full mt-1 px-5 py-2 bg-[#E7D081] text-center inline-block ml-1 mr-1">
                        <p className="text-[#4B3E11] text-sm font-semibold">Upcoming</p>
                      </div>}
                      {courseItem.is_new === 'true' && <div className="rounded-full mt-1 px-5 py-2 bg-[#BBEACF] text-center inline-block ml-1 mr-1">
                        <p className="text-[#007833] text-sm font-semibold">New</p>
                      </div>}
                      {courseItem.is_trending === 'true' && <div className="rounded-full mt-1 px-5 py-2 bg-[#BBEACF] text-center inline-block ml-1 mr-1">
                        <p className="text-[#007833] text-sm font-semibold">Trending</p>
                      </div>}
                      {courseItem.is_popular === 'true' && <div className="rounded-full mt-1 px-5 py-2 bg-[#BBEACF] text-center inline-block ml-1 mr-1">
                        <p className="text-[#007833] text-sm font-semibold">Popular</p>
                      </div>}
                    </div>
                    <StarRating ratings={courseItem?.ratings} startRating={courseItem?.starRatings} />
                    <CourseCardDescription>{courseItem.description}</CourseCardDescription>
                  </CourseCardHeader>
                  <CourseCardContent>
                    <div className="grid grid-cols-12 gap-[15px] w-[100%]">
                      <div className="xl:col-span-4 lg:col-span-5 md:col-span-12 col-span-12">
                        <p className="flex items-center text-sm xs:justify-center">
                          <span className="dur_icon mr-3">
                            <Image
                              src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/our-courses/clock.webp"
                              width={20}
                              height={20}
                              alt="clock_icon"
                              fetchPriority="high"
                              loading="eager"
                              rel="preload"
                            />
                          </span>
                          <span className="sm:text-[18px] text-[16px]">Duration: <span className="font-semibold ml-1"> {courseItem.duration_hours}</span></span>
                        </p>
                      </div>
                      <div className="xl:col-span-8 lg:col-span-7 md:col-span-12 col-span-12">
                        <p className="flex items-center text-sm xs:justify-center">
                          <span className="dur_icon mr-3">
                            <Image
                              src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/our-courses/calander.webp"
                              width={20}
                              height={20}
                              alt="Calander"
                              fetchPriority="high"
                              loading="eager"
                              rel="preload"
                            />
                          </span>
                          <span className="sm:text-[18px] text-[16px]">Course Commencement: <span className="font-semibold ml-1">{(courseItem?.start_date === null || courseItem?.start_date === undefined) ? null : <>{moment(courseItem?.start_date).format('MMMM YYYY')}</>}</span></span>
                        </p>
                      </div>
                    </div>
                  </CourseCardContent>
                  <CourseCardFooter>
                    <div className="grid grid-cols-12 gap-4  w-[100%]">
                      <div className="xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 flex">
                        <ReadMore {...courseItem} />
                      </div>
                      <div className="xl:col-span-3 lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 flex">
                        {/* {session != null ? <ApplyNowPopupModule coursecid={courseItem.cid} courseid={courseItem.course_id} coursename={courseItem.title} session={session} /> : */}
                        {/* <Button className="text-base uppercase" variant="outlineprimary" >Login to Apply</Button> */}
                        {/* <Link href="/login" >
                            Login to Apply
                          </Link>
                        } */}
                        <ApplyNowPopupModule coursecid={courseItem.cid} courseid={courseItem.course_id} coursename={courseItem.title} session={session} screen="course_list"/>
                      </div>
                    </div>
                  </CourseCardFooter>
                </div>
                <div className="lg:col-span-4 md:col-span-4 col-span-12 flex items-center md:order-2 order-1 justify-center relative">
                  <div className="h-[160px] w-[89%] my-auto">
                    {courseItem?.image && <Image
                      src={courseItem?.image}
                      width={200}
                      height={200}
                      className='object-contain h-full md:max-h-[240px] max-h-[163px] mx-auto'
                      alt="clock_icon"
                      fetchPriority="high"
                      loading="eager"
                      rel="preload"
                    />}
                  </div>
                </div>
              </div>
            </CourseCard>
          ))}
          </Slider>
        </div>
      </section>
    )
  }
  export default CourseSlider;
  