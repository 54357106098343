"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from "next/image";
import { FaRegLightbulb, FaRegCalendarAlt } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa6";
import { MdArrowCircleRight, MdAssignmentAdd } from "react-icons/md";
import { PiNotebookFill, PiCertificate } from "react-icons/pi";
import { useEffect } from 'react';

const Assessments = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);
    return (
        <section className="bg-[#FAFAFA] pb-7 sm:pb-7 xl:pb-14 lg:pb-14 md:pb-14">
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-[20px] lg:gap-[50px] md:gap-[30px]">
                    <div data-aos="fade-up" data-aos-duration="1000">
                        <div className="overflow-hidden rounded-tl-[30px] rounded-br-[40px] h-auto m-auto">
                            <Image height={500} width={500} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/assessments.webp"
                                className="md:h-[265px] lg:h-[initial] object-cover mx-auto" alt="Assignment Image"
                                blurDataURL="URL"
                                placeholder="blur"
                                fetchPriority="high"
                                loading="eager"
                                rel="preload"
                            />
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1000">
                        <div className="">
                            <h2 className="text-gray text-xl lg:text-[32px] md:text-2xl font-semibold lg:mb-7 mb-3 leading-tight">Learning Path</h2>
                            {/* <p className="text-gray font-normal text-[1rem] lg:text-lg md:text-base lg:mb-4 mb-4">Easily launch live assignments, quizzes, and tests. Student have real-time experience of their skills and relevant work.</p> */}
                            {/* <div className="flex">
                                <div className="lg:me-5 md:me-5 me-2 xl:min-w-[150px] lg:min-w-[140px] md:min-w-[100px] sm:min-w-[150px] min-w-[85px] flex flex-col items-center text-center border border-[#C7C7C7] lg:px-4 md:px-4 sm:px-4 px-2 lg:py-4 md:py-3 sm:py-4 py-2 sm:w-auto w-full">
                                    <MdAssignmentAdd className="h-[35px] w-[35px] mb-3 text-[#689fff]" />
                                    <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal">Assessments</p>
                                </div>
                                <div className="lg:me-5 md:me-5 me-2 xl:min-w-[150px] lg:min-w-[140px] md:min-w-[100px] sm:min-w-[150px] min-w-[85px] flex flex-col items-center text-center border border-[#C7C7C7] lg:px-4 md:px-4 sm:px-4 px-2 lg:py-4 md:py-3 sm:py-4 py-2 sm:w-auto w-full">
                                    <FaRegLightbulb className="h-[35px] w-[35px] mb-3 text-[#689fff]" />
                                    <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal">Quizzes</p>
                                </div>
                                <div className="lg:min-w-[140px] xl:min-w-[150px] md:min-w-[100px] sm:min-w-[150px] min-w-[85px] flex flex-col items-center text-center border border-[#C7C7C7] lg:px-4 md:px-4 sm:px-4 px-2 lg:py-4 md:py-3 sm:py-4 py-2 sm:w-auto w-full">
                                    <PiNotebookFill className="h-[35px] w-[35px] mb-3 text-[#689fff]" />
                                    <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal">Tests</p>
                                </div>
                            </div> */}

                            <div className='flex flex-col relative gap-y-4 before:content-[""] before:absolute before:top-[5px] before:bottom-[15px] before:w-[4px] before:left-[18px] before:bg-[#c659d5] z-[-1]'>
                                <div className='flex space-x-3 relative'>
                                    <div className='h-[40px] w-[40px] rounded-full flex justify-center items-center bg-[#c659d5]'>
                                        <FaRegCalendarAlt className="h-[15px] w-[15px] text-[#fff]" />
                                    </div>
                                    <div>
                                        <h4 className="text-base md:text-lg font-semibold leading-tight">Faculty Live Sessions</h4>
                                        <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal">Interactive and engaging live sessions with expert instructors. </p>
                                    </div>
                                </div>
                                <div className='flex space-x-3 relative'>
                                    <div className='h-[40px] w-[40px] rounded-full flex justify-center items-center bg-[#c659d5]'>
                                        <MdAssignmentAdd className="h-[15px] w-[15px] text-[#fff]" />
                                    </div>
                                    <div>
                                        <h4 className="text-base md:text-lg font-semibold leading-tight">Assessment</h4>
                                        <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal"> Periodic assessments to measure progress and understanding.</p>
                                    </div>
                                </div>
                                <div className='flex space-x-3 relative'>
                                    <div className='h-[40px] w-[40px] rounded-full flex justify-center items-center bg-[#c659d5]'>
                                        <FaRegNewspaper className="h-[15px] w-[15px] text-[#fff]" />
                                    </div> 
                                    <div>
                                        <h4 className="text-base md:text-lg font-semibold leading-tight">Capstone Project</h4>
                                        <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal">Project to apply knowledge in a real-world scenario. </p>
                                    </div>
                                </div>
                                <div className='flex space-x-3 relative'>
                                    <div className='h-[40px] w-[40px] rounded-full flex justify-center items-center bg-[#c659d5]'>
                                        <PiCertificate className="h-[15px] w-[15px] text-[#fff]" />
                                    </div>
                                    <div>
                                        <h4 className="text-base md:text-lg font-semibold leading-tight">Certificate</h4>
                                        <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal"> Earn a certificate upon successful course completion.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Assessments;