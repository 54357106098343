'use client';
import { useState } from 'react';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';

const StarRating = ({ ratings, startRating }: any) => {
    const [rating, setRating] = useState(4.5); // Optional: Can manage internal rating state
    const [hover, setHover] = useState(0);

    return (
        <div className='flex items-center'>
            {/* Display average rating */}
            <p className='pe-1 lg:text-sm md:text-sm text-[12px]'>{`(${startRating})`}</p>
            <div className="star-rating">
                <div className='flex flex-row'>
                    {[...Array(5)].map((_, index) => {
                        const starValue = index + 1; // Star index starts from 1
                        if (starValue <= Math.floor(startRating)) {
                            // Full Star
                            return (
                                <FaStar className="text-[#eeac01] mx-[2px]" key={index} />
                            );
                        } else if (starValue - startRating <= 0.5) {
                            // Half Star
                            return (
                                <FaStarHalfAlt className="text-[#eeac01] mx-[2px]" key={index} />
                            );
                        } else {
                            // Empty Star
                            return (
                                <FaRegStar className="text-gray-300 mx-[2px]" key={index} />
                            );
                        }
                    })}
                </div>
            </div>
            {/* Divider */}
            <p className='lg:px-5 md:px-5 px-2'>|</p>
            {/* Display enquiry count */}
            <p className='lg:text-base md:text-base text-[12px] font-normal'>{ratings} Enquiries</p>
        </div>
    );
};

export default StarRating;
