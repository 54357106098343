"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from "next/image";
import { useEffect } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight  } from "react-icons/md";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

const OurLearnersSpeak  = () => {

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);

    const PrevArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-prev">
                <MdKeyboardArrowLeft className="sm:text-[28px] text-[28px] hover:!text-[#000000]" />
            </div>
        );
    };

    const NextArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-next">
                <MdKeyboardArrowRight className="sm:text-[28px] text-[28px] hover:!text-[#000000]" />
            </div>
        );
    };

    var settings = {
        dots: true,
        nav: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        pauseOnHover: true,
        lazyLoad: true,
        arrows: true,
        customNav: false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
       
    };

    const sliderData = [
        {
            id: 0,
            title: "A great decision to enroll with ExC",
            name:"Nada Al-Sayyad ",
            role:"SAP Ariba Associate",
            sub_title:"SAP Ariba Associate Consultant",
            image: "https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/user-vector.png",
            comment: "I'm grateful for the enriching experience at ExC Academy as a SAP Ariba aspirant. I was able to enhance my skills and professional growth by working on live projects. The supporting environment and mentorship were invaluable.",
        },
        {
            id: 1,
            title: "Highly recommended!",
            name:"Naveen Veeran",
            role:"SAP Ariba Consultant",
            sub_title:"SAP Ariba Consultant",
            image: "https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/user-vector.png",
            comment: "I started my career as a fresher after being trained at the learning of Exalogic Consulting i.e., \"ExC Academy\". The training was spot on with practical application on projects. The Ariba team was ready to support all time. Today, I am working as an integration consultant only because of being nurtured by the team.",
        },
        {
            id: 2,
            title: "Relevant project guidance",
            name:"Niketan Kolati",
            role:"SAP Ariba Consultant",
            sub_title:"SAP Ariba Consultant",
            image: "https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/user-vector.png",
            comment: "I joined Exalogic Consulting with minimal experience in manufacturing  and none in IT. They provided a platform to learn SAP Ariba and work on UAE projects. In two years, I gained valuable IT and SAP knowledge, leading to an MNC opportunity.",
        },
        {
            id: 3,
            title: "Worth it!",
            name:"Amol Shivaji Koli",
            role:"SAP Ariba Consultant",
            sub_title:"SAP Ariba Associate Consultant",
            image: "https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/user-vector.png",
            comment: "I have been associated with Exalogic Consulting for over 2 years now. I can say that I feel empowered with the kind of experience I had with ExC Academy during my grooming period. The academy has helped me a lot to improve my functional and technical skills.",
        },

    ];

    return (
        <section className='xl:py-14 lg:py-14 md:py-14 py-7 overflow-hidden speakers relative'>
            <div className="container" >
                <h2 className='md:text-[40px] text-[28px] font-medium text-center mb-4'>Our Learners Speak </h2>
                <Slider {...settings} className="leader-speak">
                    {sliderData.map((item, index) => (
                        <div key={index} className="border drop-shadow rounded-md overflow-hidden">
                            <div className='p-4 md:p-8'>
                                <p className='mb-4 font-semibold text-[16px]'>{item.title}</p>
                                <p className='text-[14px]'>{item.comment}</p>
                            </div>
                            <div className='bg-[#F0F0F0] py-3 px-4 md:px-8 flex space-x-3 items-center'>
                                <Image src={item.image} alt="ellipe" width='60' height='60' />
                                <div>
                                    <p className='m-0 tex-[14px] font-semibold tex-[#383838]'>{item.name}</p>
                                    <p className="tex-[11px] text-[#555555]">{item.role}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    )
}

export default OurLearnersSpeak ; 