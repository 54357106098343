"use client"

import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTrigger } from "../ui/custom-dialog";

import { DialogDescription, DialogTitle } from "../ui/dialog";
import Link from "../ui/link";
import { FaLongArrowAltLeft } from "react-icons/fa";
import Stepper from "../course-registration/stepper";
import { useRef, useState } from "react";
import Image from "next/image";
import { FormProvider } from "@/lib/FormContext";
import { AiOutlineClose } from "react-icons/ai";


const ApplyNowPopupModule = (props: any) => {

    const regBtnRefClose = useRef<any>(null);
    const regBtnRef = useRef<any>(null);
    const [open, setOpen] = useState(false);
   
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const popupOpen = (val:any) => {
        setOpen(false);
    }
    return (
        <>
        {/* <Button className="uppercase px-5 py-4 w-full text-center font-semibold text-base lg:inline-block md:inline-block block" variant="outlineprimary" onClick={handleOpen}>Apply Now</Button> */}
        <Button className="uppercase px-5 py-4 w-full text-center font-semibold text-base lg:inline-block md:inline-block block" variant="outlineprimary" onClick={handleOpen}>{props?.screen === "course_list" ? "Talk to Us" : "Apply Now"}</Button>
        <Dialog open={open}>
            {/* <DialogTrigger asChild className="">
                
            </DialogTrigger> */}
            <DialogContent className="lg:max-w-[70%] max-w-[95%] max-h-full custom-dialog">
                <DialogHeader className="z-50 shadow-lg text-start md:px-8 px-4 py-5 bg-[url('https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/our-courses/stepper_bg.webp')] relative">
                    <DialogClose asChild className="flex items-center hidden font-medium text-[16px] text-[#302A33] relative">
                        {/* <Button type="button" variant="secondary">
      Close
    </Button> */}
                        <p style={{ cursor: "pointer" }} className="bg-[transparent] m-0 hover:bg-[transparent] px-0 flex items-center border-none text-[#000000]">
                            <span className="text-[#000000] flex items-center" ref={regBtnRefClose}><FaLongArrowAltLeft />&nbsp; Back</span></p>
                    </DialogClose>
                    {/* <DialogDescription className="flex items-center font-medium text-[16px] text-[#302A33] relative">
        <Link variant="text" href="/" className="bg-[transparent] m-0 hover:bg-[transparent] px-0 flex items-center border-none text-[#000000]">
            <span className="text-[#000000] flex items-center"><FaLongArrowAltLeft />&nbsp; Back</span></Link>
    </DialogDescription> */}
                    <div className="flex justify-between items-center">
                        <DialogTitle className="lfont-medium text-[20px] sm:text-[25px] md:text-[30px] lg:text-[35px]">{props.coursename} Course Registration</DialogTitle>
                        <AiOutlineClose className="w-[24px] h-[24px] cursor-pointer" onClick={handleClose}/>
                    </div>
                    
                </DialogHeader>
                <div className="grid grid-cols-1 z-20">
                    <FormProvider>
                        <Stepper regBtnRefClose={regBtnRefClose} regBtnRef={regBtnRef} courseid={props.coursecid} coursecid={props.courseid} coursename={props.coursename} popupOpen={popupOpen} setOpen={setOpen}/>
                    </FormProvider>
                </div>
            </DialogContent>
        </Dialog><Dialog>
                <DialogTrigger asChild className="py-5">
                    <Button variant="primary" className="hidden" ref={regBtnRef}>Reg</Button>
                </DialogTrigger>
                {/* <DialogContent className="lg:max-w-[40%] max-w-[95%]">
                    <div className="bg-white p-6 m-5">
                        <div className="flex items-center w-100 justify-center">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <div className="mt-2 text-center">
                                    <h6 className='text-[16px] font-medium text-[#A126EB]'>Congratulations!</h6>
                                    <h4 className='text-[30px] font-bold text-[#000000] uppercase'>SAP - ARIBA</h4>
                                    <p className="text-[16px] text-[#000000]">
                                        Course Registration Successfully Completed.
                                    </p>
                                    <div className='my-6'>
                                        <Link variant="secondary" href='#' className="text-[#0055F9] hover:text-[#3E7DF9] hover:bg-[#FFFFFF] text-xs underline border-none font-medium inline-block">View Course Details</Link>
                                    </div>
                                    <div className='my-4'>
                                        <Image height={200} width={200} src='https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/common/success.webp' alt='Success' className="inline-block max-w-full" />
                                    </div>
                                    <p className="text-[16px] mb-5 text-[#000000]">
                                        You will hear from our executive soon.
                                    </p>
                                    <div className='block text-center mt-5'>
                                        <Link
                                            variant="secondary"
                                            href="/our-courses"
                                            className="block w-full uppercase justify-center bg-[#302A33] my-4 px-3 py-2 md:text-[16px] text-[12px] font-medium text-white hover:bg-[#111111]"
                                        >
                                            Explore More Sap Courses
                                        </Link>
                                    </div>
                                    <div className='grid grid-cols-2 flex items-center'>
                                        <Link href='/'
                                            variant="secondary"
                                            className="mt-1 inline-flex w-[50%] sm:w-full justify-center px-2 sm:px-3 mx-1 sm:mx-3 py-2 text-[12px] sm:text-[14px] font-medium text-[#000000] hover:text-[#0055F9] outline-none sm:mt-0"
                                        >
                                            Go to Home
                                        </Link>
                                        <div>
                                            <DialogClose>
                                                <Button
                                                    variant="blank"
                                                    type="button"
                                                    className="mt-1 inline-flex w-[50%] sm:w-full justify-center px-2 sm:px-3 mx-1 sm:mx-3 py-2 text-[12px] sm:text-[14px] font-medium text-[#000000] hover:text-[#0055F9] outline-none sm:mt-0"
                                                >
                                                    Cancel
                                                </Button>
                                            </DialogClose>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent> */}
                <DialogContent className="max-w-[500px]">
                    <div className="bg-white p-6 m-5">
                        <div className="flex items-center w-100 justify-center">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <div className="mt-2 text-center">
                                    <div className='my-4'>
                                        <Image height={200} width={200} src='https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/common/success.webp' alt='Success' className="inline-block max-w-full w-11" />
                                    </div>
                                    <h6 className='text-[22px] font-medium text-[#A126EB]'>Registration Sucessful</h6>
                                    <h4 className='text-[30px] font-bold text-[#000000] uppercase my-4'>{props.coursename}</h4>
                                    <p className="text-[16px] text-[#000000]">
                                        You have successfully registered for the {props.coursename}.
                                    </p>
                                    {/* <div className='my-6'>
                                        <Link variant="secondary" href='#' className="text-[#0055F9] hover:text-[#3E7DF9] hover:bg-[#FFFFFF] text-xs underline border-none font-medium inline-block">View Course Details</Link>
                                    </div> */}

                                    {/* <p className="text-[16px] mb-5 text-[#000000]">
                                        You will hear from our executive soon.
                                    </p>
                                    <div className='block text-center mt-5'>
                                        <Link
                                            variant="secondary"
                                            href="/our-courses"
                                            className="block w-full uppercase justify-center bg-[#302A33] my-4 px-3 py-2 md:text-[16px] text-[12px] font-medium text-white hover:bg-[#111111]"
                                        >
                                            Explore More Sap Courses
                                        </Link>
                                    </div>
                                    <div className='grid grid-cols-2 flex items-center'>
                                        <Link href='/'
                                            variant="secondary"
                                            className="mt-1 inline-flex w-[50%] sm:w-full justify-center px-2 sm:px-3 mx-1 sm:mx-3 py-2 text-[12px] sm:text-[14px] font-medium text-[#000000] hover:text-[#0055F9] outline-none sm:mt-0"
                                        >
                                            Go to Home
                                        </Link>
                                        <div>
                                            <DialogClose>
                                                <Button
                                                    variant="blank"
                                                    type="button"
                                                    className="mt-1 inline-flex w-[50%] sm:w-full justify-center px-2 sm:px-3 mx-1 sm:mx-3 py-2 text-[12px] sm:text-[14px] font-medium text-[#000000] hover:text-[#0055F9] outline-none sm:mt-0"
                                                >
                                                    Cancel
                                                </Button>
                                            </DialogClose>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog></>
    );
}

export default ApplyNowPopupModule