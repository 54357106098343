"use client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { HiOutlineCheckCircle } from "react-icons/hi";
// import { Button } from "../ui/button";
// import EducationalQualification from "./educational-qualification";
// import Assessment from "./assessment";
import dynamic from 'next/dynamic';
import { useEffect, useState } from "react";
import { Component } from "lucide-react";
// import { DialogClose } from "../ui/dialog";
// import { Dialog, DialogContent, DialogTrigger } from "../ui/custom-dialog";
// import Link from "../ui/link";
import { useRef } from 'react'
// import CongratulationModal from "./congratulations-model";
import Image from "next/image";
import { getIpsAddress } from "@/lib/getIP";
// import { Icons } from "../ui/icons";
import ApiClient from '@/lib/getData'
import { useForm as useFormCustomContext } from '@/lib/FormContext';
import axios from 'axios';
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
const steps = [
    { component: dynamic(() => import('./general-information')), label: 'General Information', number: 0 },
    // { component: dynamic(() => import('./educational-qualification')), label: 'Educational Qualification', number: 1 },
    // { component: dynamic(() => import('./assessment')), label: 'Assessment', number: 2 },
];

const StepPanel = ({ title, isActive, completionStatus, stepNumber }: any) => {
    const getBackgroundColor = () => {
        if (isActive) {
            return 'bg-[#27AE60] text-white';
        } else if (completionStatus) {
            return 'bg-[#3d0362a3] text-white';
        } else {
            return 'bg-[#F4F4F4] text-gray';
        }
    };

    return (
        <div className={`flex items-center relative w-1/3 py-2 px-6 text-lg font-semibold text-center justify-center ${getBackgroundColor()}`}>
            {isActive && (
                <div className="w-full absolute bottom-[-4px] left-1/2 transform -translate-x-1/2">
                    <div className="w-2 h-[2px] bg-white"></div>
                    <div className="w-full h-[2px] bg-[#27AE60]"></div>
                </div>
            )}
            {completionStatus ?
                <div className="text-[#27AE60] text-base me-[5px]">
                    <span className="text-white text-md"><HiOutlineCheckCircle /></span>
                </div>
                :
                <div className={"text-sm me-[7px] " + (isActive ? "text-white" : "text-black")}>
                    {stepNumber}
                </div>
            }
            <p className="lg:text-[14px] md:text-xs text-[10px] leading-[12px]">{title}</p>
        </div>
    );
};

const Stepper = (props: any) => {
    const {setOpen}= props
    const { formCourseData, setFormCourseData, resetFormCourseData } = useFormCustomContext();
    // console.log("🚀 ~ Stepper ~ formCourseData:", formCourseData, formCourseData?.step1?.email)
    const [loading, setLoading] = useState(false)
    const step0 = useRef<HTMLInputElement>(null);
    const step1 = useRef<HTMLInputElement>(null);
    const regBtnRef = useRef<any>(null);
    const step2 = useRef<HTMLInputElement>(null);
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [stepOne, setStepOne] = useState<any>()
    const [stepTwo, setStepTwo] = useState<any>()
    const [stepThree, setStepThree] = useState<any>()
    const handleChange = (newData: any) => {
        setFormData((prevData) => ({ ...prevData, ...newData }));
    };

    const StepComponent: any = steps[step].component;
    const currentLabel = steps[step].label;

    const aadharNumberRegex = /^[1-9]\d{3}\s?\d{4}\s?\d{4}$/;

    const formatAadharNumber = (value: string) => {
        // Remove all non-digit characters
        const digitsOnly = value.replace(/\D/g, '');

        // Insert a space after every four digits
        const formattedValue = digitsOnly.replace(/(\d{4}(?!$))/g, '$1 ');

        return formattedValue;
    };

    const formSchema0 = z.object({
        username: z
        .string({ required_error: 'Name is required' })
        .min(3, { message: 'Name must be at least 3 characters' })
        .max(30, { message: 'Name cannot contain more than 30 characters' })
        .refine(
            (value) => /^[^\s][a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/.test(value),
            { message: 'Only alphabets allowed' }
        ),
        email: z.string({ required_error: "Email is required" }).min(1, "Email is required").email("Please enter valid email!"),

        // gender: z.string({ required_error: "Please select gender", }).min(1, "Gender is required"),

        // dob: z.date({
        //     required_error: "Date of birth is required",
        // }),
        // select: z.string({ required_error: "Please select country", }).min(1, "Country is required"),
        // occupation: z.string({ required_error: "Please select Occupation", }).min(1, "Occupation is required"),
        // preference: z.string({ required_error: "Please select Class Preference", }).min(1, "Class Preference is required"),
        message: z.string({ required_error: "Messageis require", }).optional().nullable(),
        // preferred_time: z.string({ required_error: "Please select Time", }).min(1, "Time is required"),
        phone: z.string({ required_error: "Phone number required", })
            .min(13, { message: 'Phone Number should contain 10 digits' })
            .max(13, { message: 'Phone Number should contain 10 digits' }).refine((value) => {
                const zeroCount = (value.match(/0/g) || []).length; 
                return zeroCount <= 7; 
              }, {
                message: 'Invalid Phone number',
              }),
    })

    // const form0 = useForm({
    //     resolver: zodResolver(formSchema0),
    //     mode: 'onChange',
    // });
    const form0 = useForm<any>({
        resolver: zodResolver(formSchema0),
        // mode: 'onChange',
        defaultValues: {
            username: formCourseData?.step1?.username || '',
            email: formCourseData?.step1?.email || '',
            // gender: formCourseData?.step1?.gender || '',
            // dob: formCourseData?.step1?.dob ,
            // select: formCourseData?.step1?.select || '',
            phone: formCourseData?.step1?.phone || '',
            message: formCourseData?.step1?.preference || '',
            // aadharnumber: formCourseData?.step1?.aadharnumber || '',
            // PANnumber: formCourseData?.step1?.PANnumber || '',
        },
    })
    // const onSubmit0 = async (data: any) => {
    //     // console.log(data);
    //     setStepOne(data);
    //     setFormCourseData((prevState: any) => ({
    //         ...prevState,
    //         step1: data,
    //     }));
    //     setStep((prevStep) => prevStep + 1);
    // };



    const formSchema1 = z.object({
        highest: z.string({ required_error: "Qualification is required", }).min(2, {
            message: "Qualification must be at least 2 characters",
        }).max(10, {
            message: "Qualification must be less than 10 characters",
        }).regex(/^[A-Za-z\s]+$/, { message: "Only alphabetic characters and spaces are allowed" }).refine((value) => /^(?!.* )[\w]+$/.test(value), {
            message: 'Only alphabets allowed',
        }),

        degree: z.string({ required_error: "Degree is required", }).min(2, {
            message: "Qualification must be at least 2 characters",
        }).max(50, {
            message: "Qualification must be less than 10 characters",
        }).regex(/^[A-Za-z\s]+$/, { message: "Only alphabetic characters and spaces are allowed" }).refine((value) => /^(?!.* )[\w]+$/.test(value), {
            message: 'Only alphabets allowed',
        }),

        institute: z.string({ required_error: "Institute is required", }).min(5, {
            message: "Institute must be at least 5 characters",
        }).max(100, {
            message: "Institute must be less than 10 characters.",
        }).refine((value) => !/\d/.test(value), {
            message: "Institute cannot contain numbers.",
        }).refine((value) => /^(?!.* )[\w\s!@#$%^&*()\-_.=]+$/.test(value), {
            message: 'Only alphabets allowed',
        }),

        passing: z.string({ required_error: "Year is required", }).min(4, {
            message: "Passing year must be at least 4 Digits long",
        }).max(4, {
            message: "Passing year must be at least 4 Digits long",
        }).refine((value) => /^(?!.* )[\d]+$/.test(value), {
            message: 'Only number allowed',
        }),

        percentage: z.string({ required_error: "Percentage is required", }).min(2, {
            message: "Percentage must be at least 2 characters",
        }).max(10, {
            message: "Invalid Percentage/CGPA",
        }).refine((value) => /^(?!.* )[\d\w\s!@#$%^&*()\-_.=]+$/.test(value), {
            message: 'Invalid Percentage/CGPA',
        }),

        experience: z.string({ required_error: "Work experience is required.", }).min(1, {
            message: "Experience must be in number",
        }).max(2, {
            message: "Experience must be less than 3 Digits long",
        }).refine((value) => /^\d+$/.test(value), {
            message: "Experience can only contain numbers",
        }),
    })

    const form1 = useForm({
        resolver: zodResolver(formSchema1),
        mode: 'onChange',
    });

    const onSubmit1 = async (data: any) => {
        setStepTwo(data);
        setFormCourseData((prevState: any) => ({
            ...prevState,
            step2: data,
        }));
        setStep((prevStep) => prevStep + 1);
    };


    const formSchema2 = z.object({
        textareas: z.any().optional(),
        textarea1: z.any().optional(),
        textarea2: z.any().optional(),
        textarea3: z.any().optional(),
    })

    const form2 = useForm({
        resolver: zodResolver(formSchema2),
        mode: 'onChange',
    });

    const [otpVerifiedReset, setotpVerifiedReset] = useState(false)
    const phone: any = form0.watch("phone");

    const onSubmit2 = async (data: any, e: any) => {
        setFormCourseData((prevState: any) => ({
            ...prevState,
            step3: data,
        }));
        e.preventDefault();
        setLoading(true)
        const ips = await getIpsAddress()
        let datas = JSON.stringify({
            "panNumber": stepOne?.PANnumber,
            "aadharNumber": stepOne?.aadharnumber,
            "dob": stepOne?.dob,
            "email": stepOne?.email,
            "gender": stepOne?.gender,
            "phone": stepOne?.phone,
            "location": stepOne?.select,
            "name": stepOne?.username,
            "degreeOfSpecialisation": stepTwo?.degree,
            "experience": stepTwo?.experience,
            "highestQualification": stepTwo?.highest,
            "instituteName": stepTwo?.institute,
            "yearOfPassing": stepTwo?.passing,
            "percentageCgpa": stepTwo?.percentage,
            "totalYearsOfExperience": stepTwo?.experience,
            "q1": data?.textareas,
            "q2": data?.textarea1,
            "q3": data?.textarea2,
            "q4": data?.textarea3,
            // "q5": data?.textareas,
            "locationAppliedFrom": ips,
            "userLogged": '',
            "courseId": props.courseid,
            "courseName": props.coursename,
            "cid": props.coursecid
        });
        // const result = await fetch('api/applyCourse', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(datas)
        // })
        // const result = await axios.post(process.env.NEXT_PUBLIC_API_DEV+'/course/applyForCourse',{body:  (datas)})
        let result: any
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.NEXT_PUBLIC_API_DEV + '/leads',
            headers: {
                'Content-Type': 'application/json'
            },
            data: datas
        };

        axios.request(config)
            .then((response) => {
                // console.log("🚀 ~ .then ~ response:", response)
                if (response?.status === 200 || response?.status === 201) {
                    resetFormCourseData()
                    props.regBtnRefClose?.current.click();
                    props.regBtnRef?.current.click();
                    form0.reset()
                    form1.reset({
                        highest:"",
                        degree:"",
                        institute:"",
                        passing:"",
                        percentage:"",
                        experience:"",  
                    })
                    form2.reset({
                        textareas: "",
                        textarea1: "",
                        textarea2: "",
                        textarea3: "",
                    })
                    setOpen(false)
                    // setError({
                    //     message: 'Registed successfully',
                    //     status: '',
                    //     isError: false,
                    //     isSuccess: true
                    // })
                    // setTimeout(() => {
                    //     setError({
                    //         message: '',
                    //         status: '',
                    //         isError: false,
                    //         isSuccess: false
                    //     })
                    //     router.replace('/login');
                    // }, 3000);
                }
                else if (response?.status === 409) {
                    console.log('err');

                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false)


        setStepThree(data);
    };

    const onSubmit0 = async (data: any, e: any) => {
        setFormCourseData((prevState: any) => ({
            ...prevState,
            step3: data,
        }));
        e.preventDefault();
        setLoading(true)
        const ips = await getIpsAddress()
        let datas = JSON.stringify({
            "name": data?.username,
            "email": data?.email,
            "phone": data?.phone,
            "source": props?.source,
            "message": data?.message,
            // "company_name": data?.companyname,
            "locationAppliedFrom": ips,
            "userLogged": '',
        });

        // const result = await fetch('api/applyCourse', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(datas)
        // })
        // const result = await axios.post(process.env.NEXT_PUBLIC_API_DEV+'/course/applyForCourse',{body:  (datas)})
        let result: any
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.NEXT_PUBLIC_API_DEV + '/leads/addContactUs',
            headers: {
                'Content-Type': 'application/json'
            },
            data: datas
        };

        axios.request(config)
            .then((response) => {
                // console.log("🚀 ~ .then ~ response:", response)
                if (response?.status === 200 || response?.status === 201) {
                    resetFormCourseData()
                    props.regBtnRefClose?.current.click();
                    props.regBtnRef?.current.click();
                    form0.reset()
                    form1.reset({
                        highest:"",
                        degree:"",
                        institute:"",
                        passing:"",
                        percentage:"",
                        experience:"",  
                    })
                    form2.reset({
                        textareas: "",
                        textarea1: "",
                        textarea2: "",
                        textarea3: "",
                    })
                    setOpen(false)
                    // setError({
                    //     message: 'Registed successfully',
                    //     status: '',
                    //     isError: false,
                    //     isSuccess: true
                    // })
                    // setTimeout(() => {
                    //     setError({
                    //         message: '',
                    //         status: '',
                    //         isError: false,
                    //         isSuccess: false
                    //     })
                    //     router.replace('/login');
                    // }, 3000);
                }
                else if (response?.status === 409) {
                    console.log('err');

                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false)


        setStepThree(data);
    };
    const handleSubmit = () => {
        handleNext();

        // props.regBtnRef?.current?.click() 
    };
    const handleNext = () => {
        // setStep((prevStep) => prevStep + 1);
        if (step === 0) {
            step0?.current?.click()
        }
        else if (step === 1) {
            step1?.current?.click()
        }
        else if (step === 2) {
            step2?.current?.click()
        }
    };


    const handlePrevious = () => {
        setStep((prevStep) => prevStep - 1);
    };

    // resetbutton///
    const formsRef = useRef<any>();
    const resetButton = () => {
        setotpVerifiedReset(false);
        if (step == 0) {
            form0.reset()
        }
        if (step == 1) {
            form1.reset({
                highest:"",
                degree:"",
                institute:"",
                passing:"",
                percentage:"",
                experience:"",  
            })
        }
        if (step == 2) {
            form2.reset({
                textareas: "",
                textarea1: "",
                textarea2: "",
                textarea3: "",
            })
        }

        // form0.reset({
        //     username: "",
        //     email: "",
        //     gender: "",
        //     dob:  null,
        //     select: "",
        //     highest: "",
        //     degree: "",
        //     institute: "",
        //     passing: "",
        //     percentage: "",
        //     aadharnumber: "",
        //     PANnumber: "",
        //     location: "",
        //     experience: "",
        //     textarea: "",
        //     textarea1: "",
        //     textarea2: "",
        //     textarea3: "",
        //     textarea4: "",
        //     textarea5: "",
        // })

    }

    return (
        <><div className="w-full bg-white relative">
            <div className="z-20">
                <div className="w-full relative z-20">
                    {/* <div className="w-full justify-between flex z-10 relative">
                        {steps.map((stepItem, index) => (
                            <StepPanel
                                key={index}
                                title={stepItem.label}
                                isActive={index === step}
                                completionStatus={index < step}
                                stepNumber={index + 1} />
                        ))}
                    </div> */}
                </div>
                <StepComponent onChange={handleChange} step={step}
                    onSubmit0={onSubmit0} form0={form0} step0={step0} phone={phone} setotpVerifiedReset={setotpVerifiedReset} otpVerifiedReset={otpVerifiedReset} cid={props.coursecid}
                    onSubmit1={onSubmit1} form1={form1} step1={step1}
                    onSubmit2={onSubmit2} form2={form2} step2={step2} />
                <div className="lg:flex flex blcok justify-between sm:px-8 px-4 pt-5 pb-5 relative">
                    <div>
                        {step > 0 ? (
                            <Button
                                className="mt-0 lg:mt-0 md:mt-0 px-10 rounded-none uppercase w-full sm:w-[120px] w-[82px] me-2"
                                type="button"
                                variant="outlineprimary"
                                onClick={() => handlePrevious()}
                            >
                                Previous
                            </Button>
                        ) : null}
                    </div>
                    <div>
                        {step === steps.length - 0 ? (
                            // <DialogClose className="w-full">
                            // <Button
                            //     className="sm:px-10 px-3 rounded-none uppercase w-full sm:w-[120px] w-[82px] me-2"
                            //     type="button"
                            //     variant="outlineprimary"
                            //     disabled={loading}
                            //     onClick={()=> {props.popupOpen('tre')}}  
                            // >
                            //     Cancel
                            // </Button>
                            null
                            // </DialogClose>
                        ) : (
                            <Button
                                className="sm:px-10 px-3 rounded-none uppercase w-full sm:w-[120px] w-[82px] me-2"
                                type="button"
                                variant="outlineprimary"
                                // onClick={() => setStep(step - 1)}
                                onClick={resetButton}
                                disabled={loading}
                            >
                                Reset
                            </Button>
                        )}
                        {step < steps.length - 1 && (
                            <Button
                                className="mt-0 lg:mt-0 md:mt-0 mb-0 sm:mb-0 sm:px-10 px-3 rounded-none uppercase w-full sm:w-[120px] w-[82px] border-2 border-black"
                                type="button"
                                onClick={() => handleNext()}
                            // disabled={!otpVerifiedReset}
                            >
                                Next
                            </Button>
                        )}
                        {step === steps.length - 1 && (
                            <>
                                {/* <DialogClose> */}
                                <Button
                                    className="mt-0 lg:mt-0 md:mt-0 mb-0 sm:mb-0 sm:px-10 px-3 rounded-none uppercase w-full sm:w-[120px] w-[82px] border-2 border-black"
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                    disabled={loading}>
                                    {loading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}Submit
                                </Button>
                                {/* </DialogClose> */}

                                {/* <Button
            className="mt-3 lg:mt-0 md:mt-0 mb-2 sm:mb-0 px-10 rounded-none uppercase w-full lg:sm:w-[120px] md:sm:w-[120px]"
            type="button"
            onClick={() => handleSubmit()}
            // onClick={() => props.regBtnRef?.current?.click()}
        >
            Submit
        </Button> */}
                            </>

                        )}
                    </div>
                </div>
            </div>
        </div></>
    );
}

export default Stepper;