"use client"
import { useEffect } from "react";
import ExploreCourses from "@/components/common/explore-courses-button";
import Image from 'next/image';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
// import { ModalForm } from "@/components/landing/modalForm";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import dynamic from 'next/dynamic'; 
import { MdKeyboardArrowLeft, MdKeyboardArrowRight  } from "react-icons/md";

const DynamicModalForm = dynamic(() => import('@/components/landing/modalForm').then(mod => mod.ModalForm),{
    ssr: false,
});

const Banner = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);

    const PrevArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-prev">
                <MdKeyboardArrowLeft className="sm:text-[40px] text-[30px]" />
            </div>
        );
    };

    const NextArrow = (props: any) => {
        const { onClick } = props;
        return (
            <div onClick={onClick} className="slick-nav slick-next">
                <MdKeyboardArrowRight className="sm:text-[40px] text-[30px]" />
            </div>
        );
    };

    var settings = {
        // dots: true,
        dots: false,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 200000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        lazyLoad: true,
        arrows: false,
        customNav: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };

    return (

        <>
        {/* <section className="overflow-hidden w-full flex items-center py-7 lg:py-0 relative h-auto lg:h-[510px] md:h-auto">
            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/hero-background.webp"
                className="absolute w-full h-full lg:h-[510px] md:h-full bg-cover bg-center" width={605} height={272}
                alt="Home Background Image"
                blurDataURL="URL"
                placeholder="blur"
                fetchPriority="high"
                loading="eager"
                rel="preload"
            />
            <div className="container">
                <div className="w-full flex-wrap sm-flex-nowrap lg:flex md:flex flex  justify-between">
                    <div className="w-full lg:w-[91%] md:w-[100%] sm:w-full order-last md:order-first z-10">
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <h1 className="text-xl md:text-3xl xl:text-5xl lg:text-4xl lg:leading-tight md-mb-16 mb-6 md-mt-0  mt-6">
                                <span className="font-semibold">Learn from experts and <br className="hidden md:block"/>elevate your career </span> <br className="hidden md:block"/>
                                with Engaging <br className="hidden md:block" /> <span className="font-semibold">Classroom Experience!</span>
                            </h1>
                            <div className="max-w-full grid grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-2 items-end justify-between">
                                <div className="lg:col-span-5 md:col-span-5 col-span-12">
                                    <ExploreCourses />
                                </div>
                                <div className="lg:col-span-7 md:col-span-7 col-span-12 bg-white rounded bg-opacity-50 px-5 py-3 flex items-center z-30 lg:w-80 md:w-64 w-[16rm]">
                                    <div className="me-4">
                                        <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/certi_icon.webp"
                                            className="rounded-[7px]" width={50} height={50}
                                            alt="smallicon"
                                            blurDataURL="URL"
                                            placeholder="blur"
                                            fetchPriority="high"
                                            loading="eager"
                                            rel="preload"
                                        />
                                    </div>
                                    <div className="">
                                        <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/sap_icon.svg"
                                            className="mb-2" width={56} height={28}
                                            alt="Sap Icon"
                                            blurDataURL="URL"
                                            placeholder="blur"
                                            fetchPriority="high"
                                            loading="eager"
                                            rel="preload"
                                        />
                                        <p className="text-[14px] md:text-sm lg:text-lg text-[#222222] text-normal m-0">Aligned Certification</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center relative order-first md:order-last">
                        <div data-aos="fade-up" data-aos-duration="1000" className=" relative lg:absolute md:absolute my-auto right-[0px] sm:right-0 md:right-0 lg:right-0">
                            <Image height={553} width={487}
                                className="object-contain h-[270px] xl:h-[450px] lg:h-auto md:h-[270px] max-w-auto xl:max-w-[600px]  lg:max-w-[450px] md:max-w-[350px]"
                                src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/banner.webp"
                                alt="home banner"
                                blurDataURL="URL"
                                placeholder="blur"
                                priority={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/*<DynamicModalForm/>*/}
            <Slider {...settings} className="banner-silder">
                {/* <div className="bg-[url(https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/banner-bg.png)] bg-cover bg-bottom bg-no-repeat">
                    <div className="container" >
                        <div className="!grid grid-cols-12 items-center">
                            <div className="sm:col-span-6 col-span-12 order-last sm:order-first pb-10 sm:pb-0">
                                <div>
                                    <h1 className="text-xl md:text-3xl xl:text-5xl lg:text-4xl lg:leading-tight md-mb-16 mb-6 md-mt-0  mt-6">
                                        Your Trusted Training Partner for <span className="text-[#B73DFF] font-semibold">SAP</span> and <span className="text-[#B73DFF] font-semibold">other courses!</span>
                                    </h1>
                                    <ExploreCourses />
                                </div>
                            </div>
                            <div className="sm:col-span-6 col-span-12 order-first sm:order-last text-center">
                                <Image height={632} width={656}
                                    className="object-contain h-[270px] lg:h-[500px] md:h-[370px] max-w-auto xl:max-w-[600px]  lg:max-w-[450px] md:max-w-[350px]"
                                    src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/banner-r1.webp"
                                    alt="home banner"
                                    blurDataURL="URL"
                                    placeholder="blur"
                                    priority={true}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="bg-[url(https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/bg-banner2.webp)] bg-cover bg-top bg-no-repeat">
                    <div className="container relative" >
                        <div className="!grid grid-cols-12 lg:items-top pt-10">
                            <div className="sm:col-span-6 col-span-12 order-last sm:order-first mb-3 pb-11 sm:pb-0">
                                <div>
                                    <h1 className="text-xl md:text-3xl xl:text-[45px] lg:text-4xl lg:leading-tight md-mb-16 mb-6 md-mt-0 mt-0 font-bold text-white">
                                        ExC Academy <br/>Powered By <br/>
                                        {/* <span className="text-[#B73DFF]">Exalogic Consulting </span> */}
                                    </h1>
                                    <hr className="border-white/20 w-3/5 mb-3"/>
                                    <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/home/exa-white-logo.svg" alt="" width='150' height='50' className="h-auto"/>
                                </div>
                            </div>
                            <div className="sm:col-span-6 col-span-12 order-first sm:order-last text-center">
                                {/* <div className=" relative lg:absolute md:absolute my-auto right-[0px] sm:right-0 md:right-0 lg:right-0"> */}
                                    <Image height={570} width={540}
                                        className="object-contain h-[270px] lg:h-[470px] md:h-[370px] max-w-auto xl:max-w-[540px] ml-auto lg:max-w-[450px] md:max-w-[350px]"
                                        src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/banner-2.webp"
                                        alt="home banner"
                                        blurDataURL="URL"
                                        placeholder="blur"
                                        priority={true}
                                    />
                                {/* </div> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Slider>

            <div className="bg-gradient-to-r from-[#AA54D3] to-[#610E88] lg:pt-6 pt-4 pb-8 px-6 lg:rounded-tl-[50px] lg:rounded-tr-[50px]  max-w-[1200px] lg:mt-[-140px] relative mx-auto">
                <div className="flex sm:justify-between justify-items-center flex-wrap text-white gap-x-3 gap-y-8">
                    <div className="flex items-center space-x-4">
                        <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/rating-icon.svg" alt="reting" width='65' height='65' className="md:w-[65px] w-[50px]"/>
                        <div>
                            <p className="lg:text-[30px] md:text-[26px] font-semibold text-[22px] mb-0">15+</p>
                            <p className="lg:text-[22px] md:text-[18px] font-semibold text-[16px] m-0">Years of Industry expertise</p>
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/success-icon.svg" alt="reting" width='65' height='65' className="md:w-[65px] w-[50px]"/>
                        <div>
                            <p className="lg:text-[32px] md:text-[26px] text-[22px] mb-0">12+</p>
                            <p className="lg:text-[24px] md:text-[18px] text-[16px] m-0">Industries Served</p>
                        </div>
                    </div>
                    <div className="flex items-center space-x-4 w-full md:w-auto">
                        <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/sap-gold-partner.png" alt="reting" width='65' height='65' className="md:w-[100px] w-[50px]"/>
                        <div>
                            <p className="lg:text-[32px] md:text-[26px] text-[22px] mb-0">SAP</p>
                            <p className="lg:text-[24px] md:text-[18px] text-[16px] m-0">Gold Partner</p>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
}

export default Banner;